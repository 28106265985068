<template>
  <div class="modal is-active">
    <div class="modal-background pointer"></div>
    <div class="modal-card has-rounded-corners">
      <section class="modal-card-body p-4">
        <div>
          <div class="container has-text-grey has-text-weight-semibold is-size-5 mb-0 mt-1">
            Please use the in-game QR code scanner instead.
          </div>
          <div class="container px-4 py-2">
            <div class="is-size-6 mb-2"><b>Step 1:</b> Open your browser app - Safari, Chrome, etc.</div>
            <div class="is-size-6 mb-2"><b>Step 2:</b> Type in "pyts.link" in the address bar.</div>
            <div class="is-size-6"><b>Step 3:</b> Scan the QR code that you have just scanned.</div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QrCodeTutorial'
}
</script>

<style>
.select-location-map{
  width: 100%;
  height: 450px;
}
</style>